<template>
    <Header :isShow="isShow" />
    <div class="warper">
        <div class="content_box" ref="header">
            <!-- <div class="banner max_big_box">
                <img src="@/assets/images/wechart.png" alt="">
            </div> -->
            <div class="content max_small_box">
                <!-- <p class="title">东莞质量检测中心</p> -->
                <div class="list" v-for="(item, index) in detailInfo" :key="index">
                    <img class="qr_CODE" :src="imgUrl + item.image" alt="">
                    <div class="text">
                        <p>{{item.desc}}</p>
                        <!-- <p>东莞质检成立于1987年，30多年来东莞质检为政府质量监督、企业质量管理</p> -->
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <!-- <div class="bottom_line"></div> -->
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import ContactTop from '../../components/contact/ContactTop';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//列表处理函数
const detailEffect = (route) => {

    //列表
    const detailInfo = ref()

    //获取详情
    const detail = async (id, page_id) => {
        let postData = {
            page_id: '/' + page_id,
            second_navigation_id: id,
        }
        await post('/contact/get_contact', postData).then((res) => {
            // console.log('微信公众号', res)
            detailInfo.value = res.data.images_content
        })
    }

    return { detailInfo, detail }
}

export default {
    name: 'ContactWechat',
    components: { Header, Footer, FloatingFrame, ContactTop },
    setup() {
        const  route = useRoute()

        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        const { detailInfo, detail } = detailEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        detail(route.params.id, route.params.page_id)

        const { imgUrl }  = api

        return { imgUrl, isShow, header, detailInfo }
    }
}
</script>

<style lang="scss" scoped>
.content_box{
    margin-top: 20px;
}
.banner{
    width: 100%;
    img{
        width: 100%;
        display: block;
    }
}
.content{
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
        display: block;
    }
    .list{
        width: 20%;
        margin-right: 6%;
        &:nth-child(4n) {
            margin-right: 0%;
        }
        @media screen and (max-width: 1200px){
            width: 100%;
        }
        .title{
            color: rgba(47, 80, 158, 100);
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            margin-top: 50px;
        }
        .qr_CODE{
            width: 60%;
            margin: 0 auto;
            margin-top: 30px;
            display: block;
            @media screen and (max-width: 1200px){
                width: 30%;
            }
        }
        .text{
            margin-top: 5px;
            p{
                color: rgba(16, 16, 16, 100);
                font-size: 14px;
                margin-bottom: 15px;
                text-align: center;
                line-height: 20px;
                @media screen and (max-width: 900px){
                    font-size: .14rem;
                }
            }
        }
    }
    
}
.bottom_line{
    width: 100%;
    height: 2px;
    margin-top: 100px;
    background: #BBBBBB;
}
</style>